import React from 'react'
import {FaSearch, FaTimes, FaComment, FaStar, FaBell, FaEnvelope, FaThLarge, FaExpand, FaBars, FaPowerOff, FaUser } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import AdminLTE, { Sidebar, Content, Row, Col, Box, Button } from 'admin-lte';
import axiosClient from '../axios_client';
import { useStateContext } from '../contexts/SideBarContext';



function AdminLTEMenu() {

    // const onLogout = (event) => {
    //     event.preventDefault()
    // }

    const {user, token, setUser, setToken} = useStateContext()
    const onLogout = (ev) => {
        ev.preventDefault()
        axiosClient.post('/logout')
        .then(() => {
            setUser({})
            setToken(null)
        })
    }

  return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="#" role="button"><FaBars/> </a>
                </li>
                <li className="nav-item d-none d-sm-inline-block"><Link to={'/'} className="nav-link">होमपेज</Link></li>
                <li className="nav-item d-none d-sm-inline-block"><Link to={'/user/panchanga'} className="nav-link">पञ्चाङ्ग</Link></li>
                <li className="nav-item d-none d-sm-inline-block"><Link to={'/'} className="nav-link">परामर्श</Link></li>
                <li className="nav-item d-none d-sm-inline-block">
                    <Link id="dropdownSubMenu1" to="#" className="nav-link dropdown-toggle">कुण्डली</Link>
                    <ul className='dropdown-menu border-0 shadow' aria-labelledby="dropdownSubMenu1">
                        <li><Link to={'/'} className="nav-link">जन्म पत्रिका</Link></li>
                        <li><Link to={'/'} className="nav-link">सप्त वर्गीय</Link></li>
                        <li><Link to={'/'} className="nav-link">षोडष वर्गीय</Link></li>
                        <li><Link to={'/'} className="nav-link">चलित चक्र</Link></li>
                    </ul>
                    
                </li>
                <li className="nav-item dropdown d-none d-sm-inline-block">
                    <Link id="dropdownSubMenu1" to="#" className="nav-link dropdown-toggle">दशा</Link>
                    <ul className='dropdown-menu border-0 shadow' aria-labelledby="dropdownSubMenu1">
                        <li> <Link to={'/'} className="nav-link dropdown-item">विंशोत्तरी</Link></li>
                        <li> <Link to={'/'} className="nav-link dropdown-item">त्रिभागी</Link></li>
                        <li> <Link to={'/'} className="nav-link dropdown-item">योगिनी</Link></li>
                    </ul>
                    
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <Link id="dropdownSubMenu1" to="#" className="nav-link dropdown-toggle">विवरण</Link>
                    <ul className='dropdown-menu border-0 shadow' aria-labelledby="dropdownSubMenu1">
                        <li><Link to={'/'} className="nav-link">षड्बल</Link></li>
                        <li><Link to={'/'} className="nav-link">दोष तथा शान्ति</Link></li>
                        <li><Link to={'/'} className="nav-link">वितृप्त घात चक्र</Link></li>
                        <li><Link to={'/'} className="nav-link">शुभवर्ग पापवर्ग</Link></li>
                        <li><Link to={'/'} className="nav-link">पञ्चधामैत्रि</Link></li>
                    </ul>
                    
                </li>
                <li className="nav-item d-none d-sm-inline-block"><Link to={'/'} className="nav-link">फलादेश</Link></li>
                <li className="nav-item d-none d-sm-inline-block"><Link to={'/'} className="nav-link">विवाह मिलान</Link></li>
                <li className="nav-item d-none d-sm-inline-block">
                    <Link id="dropdownSubMenu1" to="#" className="nav-link dropdown-toggle">पत्रिका</Link>
                    <ul className='dropdown-menu border-0 shadow' aria-labelledby="dropdownSubMenu1">
                        <li><Link to={'/'} className="nav-link">विवाह लग्न पत्रिका</Link></li>
                        <li><Link to={'/'} className="nav-link">ब्रतबन्ध लग्न पत्रिका</Link></li>
                        <li><Link to={'/'} className="nav-link">वधूको माहूर</Link></li>
                        <li><Link to={'/'} className="nav-link">वरको माहूर</Link></li>
                    </ul>
                </li>
                <li className="nav-item d-none d-sm-inline-block"><Link to={'/'} className="nav-link">ग्रहण</Link></li>
                <li className="nav-item d-none d-sm-inline-block">
                    <Link id="dropdownSubMenu1" to="#" className="nav-link dropdown-toggle">मुहूर्त</Link>
                    <ul className='dropdown-menu border-0 shadow' aria-labelledby="dropdownSubMenu1">
                        <li> <Link to={'/'} className="nav-link">मुहूर्त</Link></li>
                        <li> <Link to={'/'} className="nav-link">होरा चौघडि मुहूर्त</Link></li>
                        <li> <Link to={'/'} className="nav-link">शिववास अग्निवास</Link></li>
                        <li> <Link to={'/'} className="nav-link">दिशाशूल</Link></li>
                        <li> <Link to={'/'} className="nav-link">चन्द्रमा तथा योगिनी</Link></li>
                    </ul>                
                </li>
                <li className="nav-item d-none d-sm-inline-block"><Link to={'/'} className="nav-link">राशिफल</Link></li>
       
            </ul>

            <ul className="navbar-nav ml-auto">

                <li className="nav-item">
                    <a className="nav-link" data-widget="navbar-search" href="#" role="button"><FaSearch /></a>
                    <div className="navbar-search-block">
                        <form className="form-inline">
                            <div className="input-group input-group-sm">
                                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                                <div className="input-group-append">
                                    <button className="btn btn-navbar" type="submit"> <FaSearch /></button>
                                    <button className="btn btn-navbar" type="button" data-widget="navbar-search"><FaTimes/></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </li>

                <li className="nav-item dropdown">
                    <a className="nav-link" data-toggle="dropdown" href="#"><FaComment />
                        <span className="badge badge-danger navbar-badge">3</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a href="#" className="dropdown-item">
                        <div className="media">
                        <img src="dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" />
                        <div className="media-body">
                            <h3 className="dropdown-item-title">
                                Brad Diesel
                                <span className="float-right text-sm text-danger"><FaStar /></span>
                            </h3>
                            <p className="text-sm">Call me whenever you can...</p>
                            <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
                        </div>
                        </div>

                    </a>
                    <div className="dropdown-divider" />
                    <a href="#" className="dropdown-item">

                        <div className="media">
                        <img src="dist/img/user8-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
                        <div className="media-body">
                            <h3 className="dropdown-item-title">
                            John Pierce
                            <span className="float-right text-sm text-muted"><i className="fas fa-star" /></span>
                            </h3>
                            <p className="text-sm">I got your message bro</p>
                            <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
                        </div>
                        </div>

                    </a>
                    <div className="dropdown-divider" />
                    <a href="#" className="dropdown-item">

                        <div className="media">
                        <img src="dist/img/user3-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
                        <div className="media-body">
                            <h3 className="dropdown-item-title">
                            Nora Silvester
                            <span className="float-right text-sm text-warning"><i className="fas fa-star" /></span>
                            </h3>
                            <p className="text-sm">The subject goes here</p>
                            <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
                        </div>
                        </div>

                    </a>
                    <div className="dropdown-divider" />
                    <a href="#" className="dropdown-item dropdown-footer">See All Messages</a>
                    </div>
                </li>

                <li className="nav-item dropdown">
                    <a className="nav-link" data-toggle="dropdown" href="#">
                        <FaBell/>
                        <span className="badge badge-warning navbar-badge">15</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <span className="dropdown-header">15 Notifications</span>
                    <div className="dropdown-divider" />
                    <a href="#" className="dropdown-item">
                        <FaEnvelope/> 4 new messages
                        <span className="float-right text-muted text-sm">3 mins</span>
                    </a>
                    <div className="dropdown-divider" />
                    <a href="#" className="dropdown-item">
                        <i className="fas fa-users mr-2" /> 8 friend requests
                        <span className="float-right text-muted text-sm">12 hours</span>
                    </a>
                    <div className="dropdown-divider" />
                    <a href="#" className="dropdown-item">
                        <i className="fas fa-file mr-2" /> 3 new reports
                        <span className="float-right text-muted text-sm">2 days</span>
                    </a>
                    <div className="dropdown-divider" />
                    <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
                    </div>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-widget="fullscreen" href="#" role="button"><FaExpand/></a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button"><FaUser /></a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" onClick={onLogout} data-widget="control-sidebar" data-slide="true" href="#" role="button"><FaPowerOff /></a>
                </li>
            </ul>
        </nav>
  )
}

export default AdminLTEMenu