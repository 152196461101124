import React from 'react'
import logo from'../logo.svg'
import { FaEnvelope, FaFacebook, FaInstagram, FaLocationArrow, FaMapMarked, FaPhone, FaTiktok, FaYoutube } from 'react-icons/fa'
import { Link } from 'react-router-dom';

function Footer() {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const today = new Date();
  const year = today.getFullYear();

  return (
   <>
      <section className="as_footer_wrapper">
        <div className="container">
          <hr />
          <div className="as_footer_inner as_padderBottom80">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="as_footer_widget">
                  <div className="as_footer_logo">
                    <a href="index.html"><img src={logo} alt="" /></a>
                  </div>
                  <p>हाम्रा ऋषिहरुले समयको गणना एवं शुभाशुभको अवस्थाका बारेमा ब्याख्या गर्नुभएका विभिन्न ज्योतिष ग्रन्थहरु तथा परापूर्वकालबाट संस्कृति कै रुपमा चलि आएका मौलिक परम्पराका प्रमाणका आधारमा विश्वगुरु वेभ सफ्टवेयरको निर्माण भएको छ ।</p>
                  <div className="as_share_box">
                    <p>Follow Us</p>
                    <ul>
                      <li><a href="https://www.facebook.com/profile.php?id=100089580497517"><FaFacebook className='text-white'/></a></li>
                      <li><a href="https://www.facebook.com/profile.php?id=100089580497517"><FaYoutube className='text-white'/></a></li>
                      <li><a href="https://www.facebook.com/profile.php?id=100089580497517"><FaTiktok className='text-white'/></a></li>
                      <li><a href="https://www.facebook.com/profile.php?id=100089580497517"><FaInstagram className='text-white'/></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="as_footer_widget">
                  <h3 className="as_footer_heading">Our Services</h3>
                  <ul>
                    <li><Link to={'/'}> अनलाईन ज्योतिष परामर्श</Link></li>
                    <li><Link to={'/'}> कुण्डली मिलान</Link></li>
                    <li><Link to={'/'}> अनलाईन ज्योतिष प्रशिक्षण</Link></li>
                    <li><Link to={'/'}> अनलाइन गुरुहरु</Link></li>
                    <li><Link to={'/'}> अनलाइन पञ्चाङ्ग गणना</Link></li>
                    <li><Link to={'/'}> अनलाइन पुस्तक</Link></li>
                    <li><Link to={'/'}> अनलाईन पसल</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="as_footer_widget">
                  <h3 className="as_footer_heading">Quick Links</h3>
                  <ul>
                    <li> <Link to={'about'}>हाम्रो बारेमा</Link></li>
                    <li> <Link to={'about'}>हाम्रो सेवाहरु</Link></li>
                    <li> <Link to={'about'}>लेखहरु</Link></li>
                    <li> <Link to={'about'}>पुस्तकालय</Link></li>
                    <li> <Link to={'about'}>अनलाइन पसल</Link></li>
                    <li> <Link to={'about'}>सम्पर्क गर्नुहोस</Link></li>
                    <li> <Link onClick={() => openInNewTab('https://app.bishwaguru.com/login')}> लग - इन </Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="as_footer_widget">
                  <h3 className="as_footer_heading">Contact Us</h3>
                  <ul className="as_contact_list">
                    <li>
                      <FaMapMarked/> &nbsp;
                      <p>Pashupatinath Kathmandu Nepal</p>
                    </li>
                    <li>
                      <FaEnvelope/> &nbsp;
                      <p>
                        <a href="#">bishwaguru77@gmail.com</a><br />
                        <a href="#">hello@bishwaguru.com</a>
                      </p> 
                    </li> 
                    <li>
                      <FaPhone/> &nbsp;
                      <p>
                        <a href="#">+ (977) 9844651239 </a><br />
                        <a href="#">+ (977) 9851133099</a>
                      </p>
                    </li>
                    <li>
                      <FaFacebook/> &nbsp;
                      <p>
                        <Link onClick={() => openInNewTab('https://www.facebook.com/profile.php?id=100089580497517')}> Our Facebook Page</Link>
                      </p>
                    </li>
                    <li>
                      <FaYoutube/> &nbsp;
                      <p>
                        <Link onClick={() => openInNewTab('https://www.facebook.com/profile.php?id=100089580497517')}> Our Youtube Channel</Link>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="as_copyright_wrapper text-center text-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p>Copyright © {year}  विश्व गुरु अनलाइन वेब सफ्टवेयर. All Right Reserved.</p>
            </div>
          </div>
        </div>
      </section>
  </>


  )
}

export default Footer