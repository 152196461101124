import React, { useEffect, useState } from "react"
// import {FaChevronLeft, FaChevronRight } from "react-icons/fa"
import axios from 'axios'

function Calender() {
    const [today, setToday] = useState([]);
    const [monthCalender, setMonthCalendar] = useState([]);
    const [availableYears, setAvailableYears] = useState([]);
    const [availableMonths, setAvailableMonths] = useState([]);
    const [todayYear, setTodayYear] = useState([]);
    const [todayMonth, setTodayMonth] = useState([]);

    useEffect(()=>{
        client.get().then((response)=>{
            setToday(response.data);
            setTodayYear(response.data['y'])
            setTodayMonth(response.data['m'])
        })
    },[])

    const client = axios.create({
        baseURL : `${process.env.REACT_APP_.BASE_URL}/api/today`
    })

    const calenderAxios = axios.create({
        baseURL : `${process.env.REACT_APP_.BASE_URL}/api/calender/${todayYear}/${todayMonth}`
    })

    const availableYearsAxios = axios.create({
        baseURL : `${process.env.REACT_APP_.BASE_URL}/api/available-years`
    })

    const availableMonthAxios = axios.create({
        baseURL : `${process.env.REACT_APP_.BASE_URL}/api/available-months`
    })

    useEffect(()=>{
        calenderAxios.get().then((res)=>{
            setMonthCalendar(res.data);
        })
    },[todayYear,todayMonth ])

    useEffect(()=>{
        availableYearsAxios.get().then((res)=>{
            setAvailableYears(res.data);
        })
    },[])

    useEffect(() => {
        availableMonthAxios.get().then((res) => {
            setAvailableMonths(res.data);
        })
    },[])

    return (
    <>
        <section className="as_padderTop80 as_padderBottom80">
            <div className="container">
                <div className="row">
                <div className="col-lg-12 text-center">
                    <h1 className="as_heading as_heading_center">नेपाली क्यालेन्डर</h1>
                </div>
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                        <div className="row pt-2 pb-2" style={{'backgroundColor':'#eee'}}>
                            <div className="col"> आजको मिति : {today['l']}, {today['y'] } { today['M']} {today['d']} गते </div>
                            <div className="col">
                                <select className="form-control" onChange={e => setTodayYear(e.target.value)}>
                                    {
                                        availableYears.map(year => {
                                            return(
                                                <option value={year.yearEng} key={year.id} selected={ year.selected }>{year.yearNp}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col">
                                <select className="form-control" onChange={e => setTodayMonth(e.target.value)}>
                                    {
                                        availableMonths.map(mmonth => {
                                            return(
                                                <option  value={mmonth.id} key={mmonth.id} selected={mmonth.selected}>{mmonth.month}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col"><div className="text-center"> AD : {today['ey']} {today['em']} {today['ed']}, {today['el']} </div></div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="d-none d-sm-block" ><small>आइतवार <br/>Sunday</small></p>
                                <p className="d-block d-sm-none" ><small>आइत<br/>Sun</small></p>
                            </div>
                            <div className="col">
                                <p className="d-none d-sm-block" > <small>सोमवार <br/>Monday</small></p>
                                <p className="d-block d-sm-none" ><small>सोम<br/>Mon</small></p>
                            </div>
                            <div className="col">
                                <p className="d-none d-sm-block" ><small>मङ्गलवार <br/>Tuesday</small></p>
                                <p className="d-block d-sm-none" ><small>मङ्ग<br/>Tue</small></p>
                            </div>
                            <div className="col">
                                <p className="d-none d-sm-block" ><small>बुधवार <br/>Wednesday</small></p>
                                <p className="d-block d-sm-none" ><small>बुध<br/>Wed</small></p>
                            </div>
                            <div className="col">
                                <p className="d-none d-sm-block" ><small>बिहिवार <br/>Thursday</small> </p>
                                <p className="d-block d-sm-none" ><small>बिहि<br/>Thu</small></p>
                            </div>
                            <div className="col">
                                <p className="d-none d-sm-block" > <small>शुक्रवार <br/>Friday</small></p>
                                <p className="d-block d-sm-none" ><small>शुक्र<br/>Fri</small></p>
                            </div>
                            <div className="col">
                                <p className="d-none d-sm-block" ><small>शनिवार <br/>Saturday</small></p>
                                <p className="d-block d-sm-none" ><small>शनि<br/>Sat</small></p>
                            </div>
                        </div>

                        <div className="row">
                            {
                            monthCalender.map(day => {
                                return (
                                    <>
                                    {
                                        day.npDay != 0 
                                        ?
                                        <div className="col text-center border" key={day.id}> 
                                            <p><small>{day.occasion}</small> </p>
                                            <h5>{day.npDay}</h5> 
                                            <small> 
                                                <span className="float-start">{day.engDay} </span> 
                                                <span className="float-end">{day.tithi} </span> 
                                            </small>
                                        </div>
                                        :
                                        <div className="col text-center border" key={day.id}> </div>
                                    }

                                    { (day.id)%7 == 0 && day.id != 0
                                        ? <div className="row"></div>
                                        :null
                                    }
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Calender