import axios from 'axios';
import { React, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BlogSideBar from "../components/BlogSideBar";

function BlogSingle() {
  let {id} = useParams();
  let client = axios.create({
    baseURL:`${process.env.REACT_APP_.BASE_URL}/api/blog/${id}`
  });
 
  const [article, setArticle] = useState([]);

  useEffect(() => {
    client.get().then((response) => {
      setArticle(response.data);
      
    });
  },[id]);

  // const description = article.blog_description;
  
  return (
    <section className="as_servicedetail_wrapper as_padderBottom80 as_padderTop80">
      <div className="container">
        <div className="row">
          <div className="col-lg-9 col-md-8">
            <div className="as_blog_box as_blog_single as_padderBottom80">
              <div className="as_blog_img">
                <img src={article.image} alt="" className="img-responsive" />
              </div>
              <ul className="float-end">
                <li><a href="#"><img src="assets/images/svg/user.svg" alt="" />By - Admin</a></li>
                <li><a href="#"><img src="assets/images/svg/comment.svg" alt="" />0 comments</a></li>
                <li><a href="#"><img src="assets/images/svg/comment.svg" alt="" />July 29, 2020</a></li>
              </ul>
              <h4 className="as_subheading">{article.blog_title}</h4>
              <div dangerouslySetInnerHTML={{__html: article.blog_description}}></div>
            </div>

            {/* <div className="as_about_admin">
              <div className="as_aboutImage">
                <img src="assets/images/admin.jpg" alt="" className="img-responsive" />
              </div>
              <div className="as_aboutDetail">
                <h3 className="as_subheading">About Admin - David Parker</h3>
                <p>Consectetur adipiscing elit, sed do eiusmod tempor incididunt uesdet labore eedseest dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risusds commodo viverra maecenas accumsan lacus vel facilisis.</p>  
              </div>
            </div> */}
{/* 
            <div className="as_comment_section as_padderTop80">
              <h1 className="as_heading">Comments ( 03 )</h1>
              <ul>
                <li>
                  <div className="as_comnt_img">
                    <img src="assets/images/coment1.jpg" alt="" className="img-responsive" />
                  </div>
                  <div className="as_comnt_detail">
                    <h4 className="as_subheading as_margin0">Joanne Theresa</h4>
                    <span className="as_font14"><img src="assets/images/svg/calender1.svg" alt="" /> July 29, 2020</span>
                    <p className="as_font14">Consectetur adipiscing elit, sed do eiusmod tempor incididunt uesdet labore eedseest dolore magna alaeseeuis ipsum suspendisse ultrices gravida. Risusds commodo viverra maecenas Lorem ipsum dolor sit ametonsectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a href="#" className="btn btn-success"><img src="assets/images/svg/reply.svg" alt="" /> reply</a>
                  </div>
                </li>
                <li>
                  <ul>
                    <li>
                      <div className="as_comnt_img">
                        <img src="assets/images/coment2.jpg" alt="" className="img-responsive" />
                      </div>
                      <div className="as_comnt_detail">
                        <h4 className="as_subheading as_margin0">Joanne Theresa</h4>
                        <span className="as_font14"><img src="assets/images/svg/calender1.svg" alt="" /> July 29, 2020</span>
                        <p className="as_font14">Consectetur adipiscing elit, sed do eiusmod tempor incididunt uesdet labore eedseest dolore magna alaeseeuis ipsum suspendisse ultrices gravida. Risusds commodo viverra maecenas Lorem ipsum dolor sit ametonsectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                        <a href="#" className="btn btn-success"><img src="assets/images/svg/reply.svg" alt="" /> reply</a>
                      </div>
                    </li>

                  </ul>
                </li>

              </ul>
            </div> */}

            <div className="as_comment_form">
              <h1 className="as_heading">Leave A Comments</h1>
              <p className="as_font14 as_padderBottom40">Your email address will not be published. Required fields are marked *</p>
              <form>
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <input type="text" name="name" className="form-control" placeholder="Enter Name Here..." />
                    </div>
                    <div className="form-group">
                      <input type="text" name="email" className="form-control" placeholder="Enter Email Here..." />
                    </div>
                    <div className="form-group">
                      <input type="text" name="website" className="form-control" placeholder="Enter Website Here..." />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <textarea name="message" className="form-control" placeholder="Message Here..." defaultValue={""} />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <a href="#" className="btn btn-success">post comment</a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        <BlogSideBar/>
        </div>
      </div>
    </section>
  )
}

export default BlogSingle