import React, { useEffect } from "react";
import Sidebar from "../components/AdminLTESidebar";
import { Outlet, Navigate } from "react-router-dom";
import AdminLTEMenu from "../components/AdminLTEMenu";
import '../css/adminlte.css'
import { useStateContext } from "../contexts/SideBarContext";
import axiosClient from "../axios_client";

function DefaultLayout(){
    const {user, token, setUser} = useStateContext()

    useEffect(() => {
        axiosClient.get('/user')
        .then(({data}) => {
            setUser(data)
        });
    }, []);

    if(!token){
        return <Navigate to='/login' />
    }

    return (       
        <div className="wrapper">
            <AdminLTEMenu/>
            <Sidebar/>
            <Outlet/>
    
            <aside className="control-sidebar control-sidebar-dark">
                <div className="p-3">
                <p>दर्ता नम्बर : { user.id}</p>
                <p>{user.title}</p>
                <p>{user.name}</p>
                <p>{user.phone_no}</p>
                <p>{user.email}</p>
                </div>
            </aside>
    
            <footer className="main-footer">
                <div className="float-right d-none d-sm-inline">
                    Anything you want
                </div>
                <strong>Copyright © 2018-2023 <a href="https://goinfosys.com.np">Go Infosys</a>.</strong> All rights reserved.
            </footer>
        </div>
    )
}

export default DefaultLayout;