import React from 'react';
import Calender from '../components/Calender';
import ServicesSection from '../components/ServicesSection';
import BlogsSection from '../components/BlogsSection';
import ProductSection from '../components/ProductSection';
import RashifalSection from '../components/RashifalSection';

function Home(){
    return(
        <>
            <Calender/>
            <RashifalSection/>
            <ServicesSection/>
            <BlogsSection/>
            <ProductSection/>
        </>
    )
}

export default Home;