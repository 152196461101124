import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import {Link} from 'react-router-dom'

function NotFound() {
  return (
    <>
      <Header/>
        <div className="as_error_wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h1 className="as_margin0">404</h1>
                <h2 className="as_margin0 as_padderBottom20">Page not found</h2>
                <p className="as_margin0 as_padderBottom30">Oops.The Page You're Looking For is Not Available Now.</p>
                <Link to={'/'} className="as_btn">Go Back to Home</Link>
              </div>
            </div>
          </div>
        </div>

      <Footer/>
    </>
  )
}

export default NotFound