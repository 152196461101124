import {React, useState, useEffect} from 'react'
import axios from 'axios'

function ServicesSection() {

  const client = axios.create({
    baseURL:`${process.env.REACT_APP_.BASE_URL}/api/service`
  });

  const[services, setService] = useState([]);

  useEffect(() => {
    client.get().then((response) => {
      setService(response.data)
    })
  },[])

  return (
    <section className="as_service_wrapper as_padderTop80 as_padderBottom80">
      {/* <hr /> */}
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <h1 className="as_heading as_heading_center">हम्रो सेवाहरु</h1>
        </div>

        {
          services.map(service => {
            return (
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12" key={service.id}>
              <div className="as_service_box text-center text-white">
                <h4 className="as_subheading">{service.title}</h4>
                {/* <p>{service.excerpt}</p> */}
                <a href={service.slug} className="as_link">read more</a>
              </div>
            </div>
            )
          })
        }

      </div>
    </div>
  </section>
  )
}

export default ServicesSection