import React, { useState } from 'react'
import axios from 'axios'
import { FaEnvelope, FaPhone } from 'react-icons/fa'

function ContactUs() {

  const [userName, createUser] = useState([])

  const onSubmit = async (e) => {
        e.preventDefault()
        const post = { userName: userName }

        try {
          const res = await axios.post(`${process.env.REACT_APP_.BASE_URL}/api/contact-us`, post)
          console.log(res.data)
        } catch (e) {
          alert(e)
        }
      }
 
  return (
    <div className="as_main_wrapper">
      <section className="as_blog_wrapper as_padderTop50 as_padderBottom50">
        <div className="container">
          <div className="row">
              <div className="col-lg-12 text-center">
              <h1 className="as_heading as_heading_center">सम्पर्क गर्नुहोस</h1>
              </div>
          </div>
        </div>
      </section>
 
    <section className="as_contact_section as_padderTop80">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="as_contact_info">
              <h1 className="as_heading">सम्पर्क जानकारी</h1>
              <p className="as_font14 as_margin0">सल्लाह सुझाव तथा कुनै पनि प्रतिक्रिया को लागि सम्पर्क गर्नु होला।  </p>
              <div className="row">
                <div className="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-xs-12">
                  <div className="as_info_box">
                    <span className="as_icon text-white"><FaPhone/></span>
                    <div className="as_info">
                      <h5>Call Us</h5>
                      <p className="as_margin0 as_font14">+ (977) 9844651239</p>
                      <p className="as_margin0 as_font14">+ (977) 9851133099</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-xs-12">
                  <div className="as_info_box">
                    <span className="as_icon text-white"><FaEnvelope/></span>
                    <div className="as_info">
                      <h5>Mail Us</h5>
                      <p className="as_margin0 as_font14"><a href="#"> bishwaguru77@gmail.com</a></p>
                      <p className="as_margin0 as_font14"><a href="#">hello@bishwaguru.com</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="as_contact_form">
              <h4 className="as_subheading">Have A Question?</h4>
              <form onSubmit={onSubmit}>
                <div className="form-group">
                  <label>Full Name</label>
                  <input 
                  type="text" 
                  name="userName" 
                  className="form-control" 
                  onChange={(event) => {
                    createUser(event.target.value)
                  }}
                  value={userName}
                  />
                </div>
                <div className="form-group">
                  <label>Email Address</label>
                  <input type="text" name="email" className="form-control" />
                </div>
                <div className="form-group">
                  <label>Message</label>
                  <textarea name="message" className="form-control" defaultValue={""} />
                </div>
                <button type="submit" className="btn btn-success">Send Message</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="as_map_section">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d883.0312790281741!2d85.344682!3d27.713423!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb197a0abda05d%3A0x113f5dae44c75759!2sPashupati%20Multiple%20Campus%20(PMC)!5e0!3m2!1sen!2snp!4v1676037644389!5m2!1sen!2snp" width="100%" height="743px" style={{border: 0}} allowFullScreen loading="lazy" />
    </section>
    <section className="as_footer_wrapper as_padderTop80">
      <div className="container">
        <div className="as_newsletter_wrapper as_padderBottom60">
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
              <h1 className="as_heading">Our Newsletter</h1>
              <p>Get Your Daily Horoscope, Daily Lovescope and Daily<br /> Tarot Directly In Your Inbox</p>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12">
              <div className="as_newsletter_box">
                <input type="text" name="email" className="form-control" placeholder="Enter your Email Here..." />
                <a href="#" className="btn btn-success">subscribe now</a>
              </div>
            </div>
          </div>
        </div>
    
      </div>
    </section>

  </div>
  
  )
}

export default ContactUs