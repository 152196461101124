import React, { useEffect, useRef, useState } from 'react'
import { useStateContext } from "../contexts/SideBarContext";
import { format } from 'date-fns'

function Sidebar(){

    const yearRef = useRef();
    const monthRef = useRef();
    const dayRef = useRef();

    const year = format(new Date(), 'yyyy')
    const month = format(new Date(), 'MM')
    const day = format(new Date(), 'dd')
    const {date, setDate} = useStateContext();

    // console.log(day);

    const SetValues = (event) => {
        event.preventDefault();
        localStorage.setItem('YEAR', yearRef.current.value);
        localStorage.setItem('MONTH', monthRef.current.value);
        localStorage.setItem('DAY', dayRef.current.value);
    }

    console.log(localStorage.getItem('YEAR'))
    console.log(localStorage.getItem('MONTH'))
    console.log(localStorage.getItem('DAY'))

    // setDate(dateRef.current.value);
    // console.log(yearRef.current);

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4" style={{'background' : '#DC3545'}}>
        <div className="sidebar text-white text-xs">
            <div className="info mt-2"><p className="text-center">जानकारी भर्नुहोस्</p></div>
            <hr/>
            <div className="form-inline mb-1" >
                <div className="input-group" >
                <label className='mr-1'>Date</label>
                   <input type="text" ref={yearRef}   className='form-control' value={localStorage.getItem('YEAR')}/>
                   <input type="text" ref={monthRef}  className='form-control' value={localStorage.getItem('MONTH')}/>
                   <input type="text" ref={dayRef}  className='form-control' value={localStorage.getItem('DAY')}/>
                </div>
            </div>

            <div className="mb-1">
               <button className='btn btn-primary btn-block btn-xs' onClick={SetValues} type="submit">Set Values</button>
            </div>
        </div>
    </aside>
  )
}

export default Sidebar