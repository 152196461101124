import React from "react";
import { Link } from "react-router-dom";

function ProductCard({props}){
    return(
            <div className="col-lg-3 col-md-6">
              <div className="as_product_box">
                <div className="as_product_img">
                  <img src={props.image} alt="" className="img-responsive" />
                </div> 
                <h4 className="as_subheading">{props.product_name}</h4>
                <p className="as_price">रु. {props.product_price} </p>
                <div className="d-grid gap-2 col-12 mx-auto">
                  <Link to={`/product/${props.id}`} className="btn btn-success">View Product</Link>
                </div>
              </div>
            </div>
    )
}

export default ProductCard;