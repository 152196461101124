import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'

const client = axios.create({
  baseURL:`${process.env.REACT_APP_.BASE_URL}/api/blog-categories`
})

const recentBlogs = axios.create({
  baseURL:`${process.env.REACT_APP_.BASE_URL}/api/recent-blogs`
})

function BlogSideBar() {

  const [categories, setCategories] = useState([]);
  const [recentPosts, setPosts] = useState([]);

useEffect(()=>{
  client.get().then((response) => {
    setCategories(response.data)
  })
},[]);

  useEffect(() => {
    recentBlogs.get().then((response) => {
        setPosts(response.data.data);
     });
  }, []);

  return (
    <>
        <div className="col-lg-3 col-md-4">
            <div className="as_blog_sidebar">
              <div className="as_search_widget">
                <input type="text" name="search" className="form-control" placeholder="Search..." />
                <a href="#"><img src="assets/images/svg/search.svg" alt="" /></a>
              </div>
              <div className="as_service_widget as_padderBottom40">
                <h3 className="as_heading">Categories</h3>
                <ul> 
                  {
                    categories.map(category => {
                      return (
                        <li key={category.id}>
                            <Link to={'/'}>
                              <span>{category.blog_category_name}</span>
                            </Link>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
              <div className="as_service_widget as_padderBottom40">
                <h3 className="as_heading">Recent Posts</h3>
                <ul className="as_recentposts">

                  {
                    recentPosts.map(post => {
                      return (
                        <li key={post.id}>
                        
                          {/* <div className="as_img">
                            <img src="assets/images/rpost1.jpg" alt="" />
                          </div> */}
                          <div className="as_detail">
                          <Link to={`/articles/${post.id}`} >
                            <h5> { post.blog_title } </h5>
                            </Link>
                          </div>
                        <hr/>
                      </li>
                      )
                    })
                  }
                </ul>
              </div>

              <div className="as_service_widget as_padderBottom40">
                <h3 className="as_heading">Tags</h3>
                <div className="as_tag_wrapper">
                  <a href="#" className="btn btn-success">विश्वगुरु</a>
                </div>
              </div>

            </div>
          </div>
    </>
  )
}

export default BlogSideBar