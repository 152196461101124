import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'


const booksClient = axios.create({
  baseURL:`${process.env.REACT_APP_.BASE_URL}/api/books`
});



function Library() {
  const [mybooks, setBooks] = useState([]);

  useEffect(() => {
    booksClient.get().then((response)=>{
      setBooks(response.data.data)
    })
  },[])
   
  return (
    <div>
      <section className="as_blog_wrapper as_padderTop50 as_padderBottom50">
        <div className="container">
          <div className="row">
              <div className="col-lg-12 text-center">
              <h1 className="as_heading as_heading_center">पुस्तकालय</h1>
              </div>
          </div>
        </div>
      </section>

      <section className="as_team_wrapper as_padderTop80 as_padderBottom50">
        <div className="container">
          <div className="row">
          {
            mybooks.map(book => {
              return(
                <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={book.id}>
                   <Link to={`/book/${book.id}`}>       
                    <div className="as_team_box text-center">
                      <div className="img-responsive">
                        <img src={book.image} alt="" className="img-responsive" />
                      </div>
                      <h3 className="as_subheading">{book.title}</h3>
                      <p>{book.author}</p>
                    </div>
                    </Link>
                  </div>
                )
            })
          }
            

          </div>
        </div>
      </section>
    </div>
  )
}

export default Library

// <Link to={`/articles/${props.id}`}>
// <img src={props.image} alt="" className="img-responsive" />
// </Link>