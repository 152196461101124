import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import {FaBars, FaUser} from 'react-icons/fa'
import logo from'../logo.svg'

import '../css/front-style.css'

function Header() {
  
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  // <button ></button>


  return (
    <>
    
    <section className="as_header_wrapper">
      <div className="as_logo">
        <Link to="/"> <img style={{width:'auto',maxHeight:'50px', minHeight:'60px'}} src={logo}/></Link>
      </div>
      <div className="as_header_detail">
        <div className="as_menu_wrapper">
          <span className="as_toggle bg_overlay "><FaBars/></span>

          <div className="as_menu">
            <ul>
              <li><Link to={'/'}>होमपेज</Link></li>
              <li><Link to={'/about'}>हाम्रो बारेमा</Link></li>
              <li><Link to={'/services'}>हाम्रो सेवाहरु</Link></li>
              <li><Link to={'/articles'}>लेखहरु</Link></li>
              <li><Link to={'/library'}>पुस्तकालय</Link></li>
              <li><Link to={'/shop'}>अनलाइन पसल </Link></li>
              <li><Link to={'/contactus'}>सम्पर्क गर्नुहोस</Link></li>
              <li>
                <Link onClick={() => openInNewTab('https://app.bishwaguru.com/login')} >
                  <button className='btn btn-sm btn-success'><FaUser/> लग - इन</button>
                  </Link>
              </li>
            </ul>
            
          </div>
        </div>
      </div>
      
    </section> 
    </>
  )
}

export default Header