import React, { useRef, useState } from 'react'
import { FaFacebookF, FaGoogle } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import axios from 'axios';

const axiosClient = axios.create({
    baseURL:`${process.env.REACT_APP_.BASE_URL}`
})

function Register() {

    const nameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmationRef = useRef();
    const titleRef = useRef();
    const phoneNoRef = useRef();
    const [errors, setErrors] = useState(null);

    const {setUser, setToken} = useState();

    const signupSubmit = (event) => {
        event.preventDefault()

        const payload = {
            name:nameRef.current.value,
            email:emailRef.current.value,
            password:passwordRef.current.value,
            password_confirmation:passwordConfirmationRef.current.value,
            title:titleRef.current.value,
            phoneNo:phoneNoRef.current.value
        }
        // console.log(payload);
        axiosClient.post('/api/user-registration', payload)
        .then(({data}) => {
            setUser(data.user)
            setToken(data.token)
        })
        .catch(err => {
            const response = err.response;
            if(response && response.status === 422){
                setErrors(response.data.errors)
            }
        })
    }

    return (
        <div className="card-body">
            <p className="login-box-msg">Register a new membership</p>
                <form onSubmit={signupSubmit} >
                    {
                        errors && <div className='alert'>
                            {
                                Object.keys(errors).map( key => (
                                    <p key={key}>{errors[key][0]}</p>
                                ))
                            }
                        </div>
                    }
                    <div className="input-group mb-3">
                        <input ref={titleRef} type="text" className="form-control" placeholder="Your Title" />
                    </div>

                    <div className="input-group mb-3">
                        <input ref={nameRef} type="text" className="form-control" placeholder="Full name" />
                    </div>
                    <div className="input-group mb-3">
                        <input ref={emailRef} type="email" className="form-control" placeholder="Email" />
                    </div>
                    <div className="input-group mb-3">
                        <input ref={phoneNoRef} type="number" className="form-control" placeholder="Phone Number" />
                    </div>
                    <div className="input-group mb-3">
                        <input ref={passwordRef} type="password" className="form-control" placeholder="Password" />
                    </div>
                    <div className="input-group mb-3">
                        <input ref={passwordConfirmationRef} type="password" className="form-control" placeholder="Retype password" />
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="icheck-primary">
                                <input type="checkbox" id="agreeTerms" name="terms" defaultValue="agree" />
                                <label htmlFor="agreeTerms">&nbsp; I agree to the <a href="#">Terms & Conditions</a></label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-primary btn-block">Register</button>
                        </div>
                    </div>
                </form>
                <div className="social-auth-links text-center">
                    <a href="#" className="btn btn-block btn-primary">
                        <FaFacebookF className='mr-2'/> Sign up using Facebook
                    </a>
                    <a href="#" className="btn btn-block btn-danger">
                        <FaGoogle className='mr-2'/>Sign up using Gmail
                    </a>
                </div>
                <Link to="/login" className="text-center">I already have a membership</Link>
        </div>
    )
}

export default Register