import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams , Link} from 'react-router-dom'


function SingleShop() {
    let {id} = useParams()
    const [product, setProduct] = useState([]);
    const [images, setImages] = useState([]);
    const [thumbs, setThumbs] = useState([]);
    const client = axios.create({
        baseURL:`${process.env.REACT_APP_.BASE_URL}/api/product/${id}`
    })
    useEffect(()=>{
        client.get().then((res) => {
            setProduct(res.data);
            setImages(res.data.images);
            setThumbs(res.data.thumbs);
        })
    },[id])

    return (
    <section className="as_shopsingle_wrapper as_padderBottom80 as_padderTop80">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-12">
                    <div className="as_shopsingle_slider">
                        <div className="as_shopsingle_for">
                            { 
                                images.map(image => {
                                    return (
                                    <div className="as_prod_img">
                                        <img src={image} alt="" className="img-responsive" />
                                    </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="col-lg-9 col-md-8 col-sm-12">
                    <div className="as_product_description">
                        <h3 className="as_subheading as_margin0 as_padderBottom10">{product.product_name}</h3>
                        <h2 className="as_price">रु. {product.product_price}</h2> 
                        <p className="as_font14 as_padderBottom10" dangerouslySetInnerHTML ={{__html:product.product_description}}></p>
                    </div>
                    <div className="row">
                    <div class="d-grid gap-2 col mx-auto"><input type="number" className='form-control' /></div>
                    <div class="d-grid gap-2 col mx-auto"><Link className="btn btn-success">Add To Cart</Link></div>
                    </div>
                </div>
            </div>
        </div>
    </section>

  )
}

export default SingleShop