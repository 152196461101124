import React, { useEffect, useState } from 'react'
import axios from 'axios'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function RashifalSection() {
    const [rashiFal, setRashiFal] = useState([])
    const client = axios.create({
        baseURL:`${process.env.REACT_APP_.BASE_URL}/api/rashifal`
    })

    useEffect(() => {
        client.get().then((res) => {
            setRashiFal(res.data);
        })
    }
        ,[])
  return (


<section className="as_customer_wrapper as_padderBottom80 as_padderTop80">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 text-center">
        <h1 className="as_heading as_heading_center">आजको राशिफल </h1>
        <div className="row ">
            <Carousel>
            {
                rashiFal.map(rashi => {
                    return (
                        <div key={rashi.id}>
                           <div className="as_customer_box text-center">
                           <span className="as_customer_img">
                               <img src={rashi.image} alt="" />
                           </span>
                           <h3>{rashi.rashiName}</h3>
                           <p>{rashi.rashiLetters}</p>
                           <p className="as_margin0">{rashi.rashifal} </p>
                            </div>
                        </div>
                    )
                })
            }
            </Carousel>
        </div>
      </div>
    </div>
  </div>
</section>




  )
}

export default RashifalSection