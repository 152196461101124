import React from 'react'
import { FaUser, FaCalendarAlt } from 'react-icons/fa'
import moment from "moment"
import {Link} from 'react-router-dom'

function BlogCard({props}) {
 
  return (
    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
    <div className="as_blog_box">
      <div className="as_blog_img">
        <Link to={`/articles/${props.id}`}>
          <img src={props.image} alt="" className="img-responsive" />
        </Link>
      </div>
      <ul>
        <li><a href="#"> <FaUser/> { props.user_id }</a></li>
        <li><a href="#"> <FaCalendarAlt/> { moment(props.published_date).format(" Do MMMM YYYY") }</a></li>
      </ul>
      <h4 className="as_subheading"><Link to={`/articles/${props.id}`}>{props.blog_title}</Link></h4>
      <p className="as_font14 as_margin0">{props.sub_title}</p>
    </div>
  </div>
  )
}

export default BlogCard