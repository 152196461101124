import React from "react";
import { Outlet } from "react-router-dom";
import Header from '../components/Header';
import Footer from '../components/Footer';

function DefaultLayout(){


    return (
        <div className="as_main_wrapper">
            <Header/>
            <Outlet/>
            <Footer/>
        </div>
    )
}

export default DefaultLayout;