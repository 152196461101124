import React, { useEffect, useState } from 'react'
import axios from 'axios'
import ProductCard from '../components/ProductCard';

function Shop() {

    const client = axios.create({
        baseURL : `${process.env.REACT_APP_.BASE_URL}/api/products`
    })

    const [products,  setProducts] = useState([]);
    useEffect(()=>{
        client.get().then((res) => {
            setProducts(res.data);    
        })
    },[])

  return (
        <section className="as_product_wrapper as_padderBottom80 as_padderTop80">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1 className="as_heading as_heading_center">Our Latest Products</h1>
              <div className="row">
                
                {
                  products.map(product => <ProductCard key={product.id} props={product}/>)
                }
               
                
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Shop