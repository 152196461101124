import React, { useRef, useState } from 'react'
import { FaEnvelope, FaFacebookF, FaGoogle, FaLock } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import axiosClient from '../axios_client';
import { useStateContext } from '../contexts/SideBarContext';

function Login() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const {setUser, setToken} = useStateContext();
    const [errors, setErrors] = useState(null);

    let loginSubmit = (event) =>{
        event.preventDefault();
        const payload = {
            email : emailRef.current.value,
            password : passwordRef.current.value,
        }
            setErrors({})
        axiosClient.post('/login', payload).then(({data}) => {
            setUser(data.user)
            setToken(data.token)
        })
       .catch(err => {
        
        const response = err.response;
        if(response && response.status === 422){
            if(response.data.errors){
                setErrors(response.data.errors)
            }else{
                setErrors({
                    email:[response.data.message]
                })
            }
        }
       })
    }

    return (
        <div className="card">
            <div className="card-body login-card-body">
                <p className="login-box-msg">Sign in to start your session</p>
                <form onSubmit={loginSubmit}>
                {
                        errors && <div className='alert'>
                            {
                                Object.keys(errors).map( key => (
                                    <p key={key}>{errors[key][0]}</p>
                                ))
                            }
                        </div>
                    }

                    <div className="input-group mb-3">
                        <input type="email" ref={emailRef} className="form-control" placeholder="Email" />
                        <div className="input-group-append">
                            <div className="input-group-text"> <FaEnvelope/> </div>
                        </div>
                    </div>
                    <div className="input-group mb-3">
                        <input type="password" ref={passwordRef}  className="form-control" placeholder="Password" />
                        <div className="input-group-append">
                            <div className="input-group-text"><FaLock/> </div>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-8">
                        <div className="icheck-primary">
                            <input type="checkbox" id="remember" />
                            <label htmlFor="remember"> &nbsp; Remember Me</label>
                        </div>
                    </div>
                    <div className="col-4">
                        <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                    </div>
                    </div>
                </form>
                <div className="social-auth-links text-center">
                    <a href="#" className="btn btn-block btn-primary">
                        <FaFacebookF className='mr-2'/> Sign up using Facebook
                    </a>
                    <a href="#" className="btn btn-block btn-danger">
                        <FaGoogle className='mr-2' />
                        Sign up using Gmail
                    </a>
                </div>
                <p className="mb-1">
                    <Link to={'/forgot-password'}>I forgot my password</Link>
                </p>
                <p className="mb-0">
                    <Link to={"/register"} className="text-center">Register a new membership</Link>
                </p>
            </div>
        </div>
    )
}

export default Login