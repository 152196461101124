import { createBrowserRouter } from 'react-router-dom'
import DefaultLayout from './layout/DefaultLayout'
import UserLayout from './layout/UserLayout'
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import AboutUs from './pages/AboutUs'
import Services from './pages/Services'
import Blogs from './pages/Blogs'
import ContactUs from './pages/ContactUs'
import Library from './pages/Library'
import BlogSingle from './pages/BlogSingle'
import Shop from './pages/Shop'
import SingleShop from './pages/SingleShop'
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import GuestLayout from './layout/GuestLayout'
import Register from './pages/Register'
import Panchanga from './pages/Panchanga'
import JanmaPatrika from './pages/JanmaPatrika'
import SaptaBargiya from './pages/SaptaBargiya'
import SodashBargiya from './pages/SodashBargiya'
import ChalitChakra from './pages/ChalitChakra'
import Dasha from './pages/Dasha'
import SingleBook from './pages/SingleBook'
import BookChapter from './pages/BookChapter'

const router = createBrowserRouter( [
    {
        path:'/',
        element: <DefaultLayout/>,
        children : [
            {
                path:'/',
                element: <Home/>
            },
            {
                path:'about',
                element:<AboutUs/>
            },
            {
                path:'/services',
                element:<Services/>
            },
            {
                path:'/articles',
                element:<Blogs/>
            },
            {
                path:'/contactus',
                element:<ContactUs/>
            },
            {
                path:'/library',
                element:<Library />
            },
            {
                path:'/shop',
                element:<Shop/>
            },
            {
                path:'/product/:id',
                element:<SingleShop/>
            },
            {
                path:'/articles/:id',
                element:<BlogSingle />
            },
            {
                path:'/book/:id',
                element:<SingleBook/>
            },
            {
                path:'/book-chapter/:id',
                element:<BookChapter/>
            }
            
        ]

    },
    {
        path:'/user/',
        element:<UserLayout/>,
        children:[
            {
                path:'/user/dashboard',
                element:<Dashboard/>
            },
            {
                path:'/user/panchanga',
                element:<Panchanga/>
            },
            {
                path:'/user/janma-patrika',
                element:<JanmaPatrika/>
            },
            {
                path:'/user/sapta-bargiya',
                element:<SaptaBargiya/>
            },
            {
                path:'/user/sodash-bargiya',
                element:<SodashBargiya/>
            },
            {
                path:'/user/chalit-chakra',
                element:<ChalitChakra/>
            },
            {
                path:'/user/dasha',
                element:<Dasha/>
            }
            
        ]
    },

    {
        path:'/',
        element:<GuestLayout/>,
        children:[
            {
                path:'/login/',
                element:<Login/>
            },
            {
                path:'register',
                element:<Register/>
            }
        ]
    },

    {
        path:'*',
        element:<NotFound/>
        
    },
    
])


export default router