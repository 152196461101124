import { createContext, useContext, useState } from "react";

const StateContext = createContext({
    user : null,
    token : null,
    setUser : () => {},
    setToken : () => {},

    date : () => {},
    setDate : () => {},

});

export const ContextProvider = ({children}) => {
    const [user, setUser] = useState({});
    const [token, _setToken] = useState(localStorage.getItem('ACCESS_TOKEN'));
    const [date, _setDate] = useState(localStorage.getItem('DATE'));

    // const [setDateAd] = useState({});
    const [dateBs, setDateBs] = useState({});

    const setToken = (token) => {
        _setToken(token)
        if(token){
            localStorage.setItem('ACCESS_TOKEN', token);
        }else{
            localStorage.removeItem('ACCESS_TOKEN')
        }
    }

    const setDate = (date) => {
        _setDate(date)
        if(date){
            localStorage.setItem('DATE', date);
        }else{
            localStorage.removeItem('DATE')
        }
    }

    return (
        <StateContext.Provider value={{
            user,setUser,
            token,setToken,
            date, setDate
        }}>
            {children}

        </StateContext.Provider>
    )
}

export const useStateContext = () =>useContext(StateContext)