import {React, useState, useEffect} from 'react'
import BlogCard from '../components/BlogCard'
import axios from 'axios'

const client = axios.create({
  baseURL: `${process.env.REACT_APP_.BASE_URL}/api/blogs` 
});

function Blogs() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
       client.get().then((response) => {
          setPosts(response.data.data);
       });
    }, []);
    
  return (
    <section className="as_blog_wrapper as_padderTop80 as_padderBottom80">
      <div className="container">
        <div className="row">
            <div className="col-lg-12 text-center">
            <h1 className="as_heading as_heading_center"> लेखहरु तथा जानकारीहरु</h1>
              <div className="text-left">
                <div className="row">
                  
                { posts.map(post => <BlogCard key={post.id} props={post}/>) }
                
                </div>
              </div>
            </div>
        </div>
      </div>
    </section>
  )
}

export default Blogs