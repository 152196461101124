import React from 'react'
import { Link } from 'react-router-dom'

function Panchanga() {
  return (
    <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-6">
                    <h1 className="m-0">पञ्चाङ्ग</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to={'/user/dashboard'}>होमपेज</Link></li>
                        <li className="breadcrumb-item active">पञ्चाङ्ग</li>
                    </ol>
                </div>
                </div>
            </div>
        </div>

        <div className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-primary card-outline">
                            <div className="card-body">
                                <h5 className="card-title">Card title</h5>
                                <p className="card-text"> Some quick example text to build on the card title and make up the bulk of card'scontent. </p>
                                <a href="#" className="card-link">Card link</a>
                                <a href="#" className="card-link">Another link</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Panchanga