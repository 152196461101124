import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useParams } from 'react-router-dom';

function SingleBook() {
    let {id} = useParams();
    const bookClient = axios.create({
        baseURL:`${process.env.REACT_APP_.BASE_URL}/api/book/${id}`
    });

  const [book, setBook] = useState([]);
  const [bookCategores, setBookCategories] = useState([]);

  useEffect(() => {
    bookClient.get().then((response)=>{
        setBook(response.data)
        setBookCategories(response.data.categories)
        console.log(response.data)
    })
  },[])

  return (
    <div>
      <section className="as_blog_wrapper as_padderTop50 as_padderBottom50">
        <div className="container">
          <div className="row">
              <div className="col-lg-12 text-center">
                <h1 className="as_heading as_heading_center">{book.title}</h1>
                <div className="as_prod_img">
                    <img src={book.image} alt="" className="img-responsive" />
                </div>
              </div>
          </div>

          <div className="row">
            <div className="col-lg-8 text-center">
            <h1 className="as_heading"></h1>
            <h3></h3>
              <div className="text-left">
                <div className="row">
                  
                    { bookCategores.map((book, index) => 

                        <div className="col-lg-12">
                           <Link to={`/book-chapter/${book.id}`}>अध्याय {index+1}. {book.name}</Link>
                           {
                            book.sub_categories.map((sub_categories, i) => 
                            <div>
                               <Link to={`/book-chapter/${sub_categories.id}`}>{sub_categories.name}</Link>
                            </div>

                          )}

                        </div>

                    )}
                
                </div>
              </div>
            </div>
        </div>
        </div>
      </section>


    </div>
  )
}

export default SingleBook
