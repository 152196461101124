import axios from 'axios';
import { React, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

function BookChapter() {
  let {id} = useParams();
  let client = axios.create({
    baseURL:`${process.env.REACT_APP_.BASE_URL}/api/book-chapter/${id}`
  });
 
  const [bookChapter, setBookChapter] = useState([]);

  useEffect(() => {
    client.get().then((response) => {
        setBookChapter(response.data);
    });
  },[id]);
  
  return (
    <section className="as_servicedetail_wrapper as_padderBottom80 as_padderTop80">
      <div className="container">
{bookChapter.map((chapter,index) =>
        <div className="row">
          <div className="col-lg-9 col-md-8">
            <div className="as_blog_box as_blog_single as_padderBottom80">
              <div className="as_blog_img">
                <img src={chapter.image} alt="" className="img-responsive" />
              </div>
  
              <h4 className="as_subheading">{chapter.name}</h4>
              <div dangerouslySetInnerHTML={{__html: chapter.content}}></div>
            </div>
          </div>
        </div>
)}
      </div>
    </section>
  )
}

export default BookChapter