import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useStateContext } from '../contexts/SideBarContext'
import '../css/adminlte.css'

function GuestLayout() {
  const {user, token} = useStateContext()
  if(token){
    return <Navigate to={'/user/dashboard'}/>
  }

  return (
    <div className='hold-transition register-page'>
        <div className="register-box">
        <div className="card card-outline card-primary">
            <div className="card-header text-center">
            <a href="/" className="h1"><b>Bishwa </b> Guru </a>
            </div>

            <Outlet/>
        
        </div>
        </div>
    </div>
  )
}

export default GuestLayout